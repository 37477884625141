import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";

function Packages() {
  const navigateToCalender = () => {
    window.open('https://www.npmjs.com/package/angular-hijri-gregorian-datepicker', '_blank');
  }
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" onClick={navigateToCalender}>
        <FaCalendarAlt />
      </Col>
    </Row>
  );
}

export default Packages;
