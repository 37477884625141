import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from '../../firebase';

import atrum from '../../assets/images/portfolio/atrumart.webp';
import football from '../../assets/images/portfolio/football.webp';
import iktissab from '../../assets/images/portfolio/iktissab.webp';
import uva from '../../assets/images/portfolio/uva.webp';
import othaim from '../../assets/images/portfolio/othaim.webp';
import hidaya from '../../assets/images/portfolio/hidaya-app.webp';
import madinah from '../../assets/images/portfolio/madinah.webp';
import makkah from '../../assets/images/portfolio/makkah.webp';
import calender from '../../assets/images/portfolio/calender.png';
import sdb from '../../assets/images/portfolio/sdb.png';







function Projects() {
  const projects = [
    {
      description: "A robust and thoroughly tested Angular Hijri/Gregorian calendar and datepicker component, compatible with Angular versions 7 through 14+. This component also supports Ionic versions 3, 4, and 5+, making it versatile for use in both iOS and Android projects.",
      isActive: true,
      image: calender,
      link: "https://www.npmjs.com/package/angular-hijri-gregorian-datepicker",
      name: "Hijri and Gregorian Calender",
      links: {
        android: "",
        ios: "",
        web: ""
      },
      order: 1,
      id: "datePicker"
    },
    {
      description: "IKTISSAB loyalty program counts on the customer and his relation with the program, also on its partners from the participating shops, so the customer can enjoy the discounts provided by those partners through a complete decent system.",
      isActive: true,
      image: iktissab,
      link: "https://play.google.com/store/apps/details?id=com.othaim.Iktissab",
      name: "Iktissab",
      links: {
        android: "https://play.google.com/store/apps/details?id=com.othaim.Iktissab",
        ios: "https://apps.apple.com/sa/app/iktissab-%D8%A7%D9%83%D8%AA%D8%B3%D8%A7%D8%A8/id590381344",
        web: "https://iktissab.othaimmarkets.com/"
      },
      order: 1,
      id: "iktissab"
    },
    {
      order: 2,
      link: "https://play.google.com/store/apps/details?id=sdb.gov.sa.app&hl=en&gl=US",
      description: "The Social Development Bank  is a govt entity to is empowering social development and enhancing the financial independence of individuals and families toward a productive society.",
      isActive: true,
      image: sdb,
      links: {
        android: "https://play.google.com/store/apps/details?id=sdb.gov.sa.app&hl=en&gl=US",
        web: "https://www.sdb.gov.sa/en-us/",
        ios: "https://apps.apple.com/us/app/%D8%A8%D9%86%D9%83-%D8%A7%D9%84%D8%AA%D9%86%D9%85%D9%8A%D8%A9-%D8%A7%D9%84%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%D9%8A%D8%A9/id1234080743"
      },
      name: "Social Development Bank",
      id: "sdb"
    },
    {
      order: 3,
      link: "https://uva.sa/#/home/uva",
      links: {
        android: "https://play.google.com/store/apps/details?id=production.uva.customer",
        ios: "https://apps.apple.com/sa/app/uva/id1485693316",
        web: "https://uva.sa/#/home/uva"
      },
      description: "A multi country website for UVA build with angular and serverless firebase cloud functions. UVA isn’t just another e-Hailing company. It’s how things should be done. Will be done. Using a network that is quality assured by us. You will know that every vehicle will be as good as the one before.",
      image: uva,
      name: "UVA",
      isActive: true,
      id: "uva"
    },
    {
      isActive: true,
      link: "https://apps.apple.com/sa/app/othaim-vendors-portal/id1249807300",
      links: {
        web: "",
        ios: "https://apps.apple.com/sa/app/othaim-vendors-portal/id1249807300",
        android: "https://play.google.com/store/apps/details?id=com.othaimmarkets.vendorportal&gl=SA"
      },
      name: "Othaim Vendor Portal",
      description: "Abdullah Al-Othaim Markets Company is a Saudi Arabia-based joint stock company, currently operating in Saudi Arabia and Egypt. Its main activities are food wholesaling, grocery stores, and malls.",
      image: othaim,
      order: 4,
      id: "vendor"
    },
    {
      name: "Othaim Intranet",
      order: 5,
      description: "Al Othaim Intranet Mobile is an application that specializes in managing the daily tasks and tasks of Abdullah Al Othaim Markets employees. Othaim Markets Company has achieved distinct steps in automating many of the internal processes related to human resources and other self-services across the company. In addition to that Othaim Intranet mobile application has been craeted to offer you fast, on the go mobile services available for you anytime & anywhere.",
      isActive: true,
      image: othaim,
      link: "https://play.google.com/store/apps/details?id=com.intranetmobile.othaimmarkets",
      links: {
        android: "https://play.google.com/store/apps/details?id=com.intranetmobile.othaimmarkets",
        web: "",
        ios: "https://www.othaimmarkets.com/mob/index.html"
      },
      id: "othaim_intranet"
    },
    {
      description: "With this booking mobile app, you can booked hotel , flights , foods , catering and many more services and  making it easy to stay in the loop with your team. You can create a booking, update the status in real-time, and contact guests – all without having to wait until you get back in front of your computer.",
      isActive: true,
      name: "Booking App",
      link: 'https://play.google.com/store/apps/details?id=com.hidayabooking.hidayabooking',
      links: {
        web: "https://umrahb2b.hidayabooking.com/",
        ios: "",
        android: "https://play.google.com/store/apps/details?id=com.hidayabooking.hidayabooking"
      },
      order: 6,
      image: hidaya,
      id: "hidayabooking_app"
    },
    {
      image: football,
      order: 7,
      name: "Football Families",
      link: 'https://www.footballfamilies.com',
      links: {
        web: "https://www.footballfamilies.com"
      },
      isActive: true,
      description: "Football Families is the social platform for all the football players, Teams ,clubs , football federation and fans.here you can follow the starts to see their news activities, you can create your account and can register your club and promote.Welcome To The Football Families World so you’ll never miss one again! In addition, the dates are automatically saved to your calendar to let you know.",
      id: "Football Families"
    },
    {
      isActive: false,
      image: atrum,
      description: "Atrum Art is a virtual platform that allows Saudi Artists to sell their art online through us as a vessel. Additionally, this also allows artists and followers to take part in online virtual auctions that are accessible from anywhere at any time.",
      name: "Atrum Art",
      link: "https://play.google.com/store/apps/details?id=sa.atrumart.www",
      order: 8,
      id: "atrum"
    },
    {
      description: "Umrah is an Islamic pilgrimage to Makkah, Hijaz, in Saudi Arabia, that is performed voluntarily by Muslims from all over the world to show their devotion towards almighty Allah. In Arabic the word Umrah means ‘to visit’ and practically entails paying a visit to Ka’bah, performing Tawaaf (circumambulation) around it, and walking between Safaa and Marwah (small hills in the Great Mosque of Makkah) seven times.",
      order: 9,
      isActive: true,
      image: madinah,
      name: "UMRAH B2B",
      link: 'https://umrahb2b.hidayabooking.com',
      links: {
        android: "https://play.google.com/store/apps/details?id=com.hidayabooking.hidayabooking",
        ios: "",
        web: "https://umrahb2b.hidayabooking.com/"
      },
      id: "umrah_b2b"
    },
    {
      name: "UMRAH B2C",
      link: 'https://umrahb2b.hidayabooking.com/',
      links: {
        web: "https://umrahb2b.hidayabooking.com/",
        ios: "",
        android: "https://play.google.com/store/apps/details?id=com.hidayabooking.hidayabooking"
      },
      image: makkah,
      isActive: true,
      description: "Umrah is an Islamic pilgrimage to Makkah, Hijaz, in Saudi Arabia, that is performed voluntarily by Muslims from all over the world to show their devotion towards almighty Allah. In Arabic the word Umrah means ‘to visit’ and practically entails paying a visit to Ka’bah, performing Tawaaf (circumambulation) around it, and walking between Safaa and Marwah (small hills in the Great Mosque of Makkah) seven times.",
      order: 10,
      id: "umrah_b2c"
    },


  ]
  // const [projects, setProjects] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchProjects = async () => {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, 'portfolios'));
  //     const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     setProjects(newData);

  //   } catch (error) {
  //     console.error('Error fetching projects:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  useEffect(() => {
    // fetchProjects();

  }, []);

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: 'white' }}>
          Here are a few projects I've worked on recently.
        </p>

        <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          {projects.map((project, i) => (
            <Col md={4} className="project-card" key={i}>
              <ProjectCard
                imgPath={project.image}
                title={project.name}
                description={project.description}
                // ghLink={project.links?.web}
                demoLink={project.link}
              />
            </Col>
          ))}
        </Row>

      </Container>
    </Container>
  );
}

export default Projects;
