import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/myImage.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              My passion for programming has driven me to continuously learn and grow in this field.
              <br />
              <br /> I have a strong foundation in programming concepts and languages, including .
              <i>
                <b className="purple">Typescript and Javascript </b>
              </i>
              <br />
              <br />
              My areas of interest include building {" "}
              <i>
                <b className="purple">Full-stack Web  </b> and  {" "}
                <b className="purple">
                  Mobile applications.
                </b>
              </i>
              <br />
              <br />
              At every opportunity, I leverage my passion for developing products with <b className="purple">Node.js, Nest.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  modern JavaScript frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> Angular and React.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={myImg}
                alt="avatar"
                className="img-fluid"
                style={{
                  width: '15rem',
                  height: '15rem',
                  borderRadius: '50%',
                  objectFit: 'fill',
                }}
              />
            </Tilt>

          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/imadkhan4"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/ImadPk"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/imadkhan4"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/i.imad.khan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
